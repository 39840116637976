.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .dropzone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .dropzone.active {
    border-color: #33adff;
  }
  
  .file-list {
    margin-top: 20px;
    width: 300px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  p {
    margin: 0;
  }
  
  h3 {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }