.recent-blog-posts .post-box {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
    overflow: hidden;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .recent-blog-posts .post-box .post-img {
    overflow: hidden;
    margin: -30px -30px 15px -30px;
    position: relative;
  }
  
  .recent-blog-posts .post-box .post-img img {
    transition: 0.5s;
  }
  
  .recent-blog-posts .post-box .post-date {
    font-size: 16px;
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
    display: block;
    margin-bottom: 10px;
  }
  
  .recent-blog-posts .post-box .post-title {
    font-size: 24px;
    color: #5C4033;
    font-weight: 700;
    margin-bottom: 18px;
    position: relative;
    transition: 0.3s;
  }
  
  .recent-blog-posts .post-box .readmore {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 1;
    transition: 0.3s;
  }
  
  .recent-blog-posts .post-box .readmore i {
    line-height: 0;
    margin-left: 4px;
    font-size: 18px;
  }
  
  .recent-blog-posts .post-box:hover .post-title {
    color: #C89F46;
  }
  
  .recent-blog-posts .post-box:hover .post-img img {
    transform: rotate(6deg) scale(1.2);
  }
  /* section {
    padding: 60px 0;
    overflow: hidden;
  } */
  
  .section-header {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #C89F46;
    text-transform: uppercase;
  }
  
  .section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #5C4033;
  }
  
  @media (max-width: 768px) {
    .section-header p {
      font-size: 28px;
      line-height: 32px;
    }
}